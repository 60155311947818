/* eslint-disable import/prefer-default-export */
// custom typefaces

// import 'typeface-montserrat';
// import 'typeface-merriweather';
// normalize CSS across browsers
import './src/normalize.css';
// custom CSS styles
import './src/style.scss';
import './src/assets/scss/theme.scss';

import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { AnimatePresence } from 'framer-motion';
import CookieConsent from 'react-cookie-consent';
import GlobalContextProvider from './src/context/GlobalContextProvider';
import ThemeContextProvider from './src/context/ThemeContextProvider';

export const wrapRootElement = ({ element }) => (
  <ThemeContextProvider>
    <GlobalContextProvider>
      {element}
    </GlobalContextProvider>
  </ThemeContextProvider>
);

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
);

export const onClientEntry = () => {
  <CookieConsent
    location="bottom"
    buttonText="Sure man!!"
    declineButtonText="Go away!!"
    cookieName="myAwesomeCookieName2"
    style={{ background: '#2B373B' }}
    buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
    expires={150}
    enableDeclineButton
    onDecline={() => {
      const cookies = document.cookie.split(';');
      alert(cookies);

      document.cookie.split(';').forEach((c) => { document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`); });
    }}
  >
    This website uses cookies to enhance the user experience.
    {' '}
    <span style={{ fontSize: '10px' }}>This bit of text is smaller :O</span>

  </CookieConsent>;
};
