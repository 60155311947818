exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-bak-index-js": () => import("./../../../src/pages/bak_index.js" /* webpackChunkName: "component---src-pages-bak-index-js" */),
  "component---src-pages-chapters-js": () => import("./../../../src/pages/chapters.js" /* webpackChunkName: "component---src-pages-chapters-js" */),
  "component---src-templates-text-page-text-page-jsx": () => import("./../../../src/templates/TextPage/TextPage.jsx" /* webpackChunkName: "component---src-templates-text-page-text-page-jsx" */),
  "component---src-templates-wp-air-exercises-post-wp-air-exercises-post-jsx": () => import("./../../../src/templates/WpAirExercisesPost/WpAirExercisesPost.jsx" /* webpackChunkName: "component---src-templates-wp-air-exercises-post-wp-air-exercises-post-jsx" */),
  "component---src-templates-wp-air-post-wp-air-post-jsx": () => import("./../../../src/templates/WpAirPost/WpAirPost.jsx" /* webpackChunkName: "component---src-templates-wp-air-post-wp-air-post-jsx" */),
  "component---src-templates-wp-biodiversity-exercises-post-wp-biodiversity-exercises-post-jsx": () => import("./../../../src/templates/WpBiodiversityExercisesPost/WpBiodiversityExercisesPost.jsx" /* webpackChunkName: "component---src-templates-wp-biodiversity-exercises-post-wp-biodiversity-exercises-post-jsx" */),
  "component---src-templates-wp-biodiversity-post-wp-biodiversity-post-jsx": () => import("./../../../src/templates/WpBiodiversityPost/WpBiodiversityPost.jsx" /* webpackChunkName: "component---src-templates-wp-biodiversity-post-wp-biodiversity-post-jsx" */),
  "component---src-templates-wp-heat-exercises-post-wp-heat-exercises-post-jsx": () => import("./../../../src/templates/WpHeatExercisesPost/WpHeatExercisesPost.jsx" /* webpackChunkName: "component---src-templates-wp-heat-exercises-post-wp-heat-exercises-post-jsx" */),
  "component---src-templates-wp-heat-post-wp-heat-post-jsx": () => import("./../../../src/templates/WpHeatPost/WpHeatPost.jsx" /* webpackChunkName: "component---src-templates-wp-heat-post-wp-heat-post-jsx" */),
  "component---src-templates-wp-introduction-post-index-js": () => import("./../../../src/templates/WpIntroductionPost/index.js" /* webpackChunkName: "component---src-templates-wp-introduction-post-index-js" */),
  "component---src-templates-wp-introduction-post-wp-introduction-post-jsx": () => import("./../../../src/templates/WpIntroductionPost/WpIntroductionPost.jsx" /* webpackChunkName: "component---src-templates-wp-introduction-post-wp-introduction-post-jsx" */),
  "component---src-templates-wp-noise-exercises-post-wp-noise-exercises-post-jsx": () => import("./../../../src/templates/WpNoiseExercisesPost/WpNoiseExercisesPost.jsx" /* webpackChunkName: "component---src-templates-wp-noise-exercises-post-wp-noise-exercises-post-jsx" */),
  "component---src-templates-wp-noise-post-wp-noise-post-jsx": () => import("./../../../src/templates/WpNoisePost/WpNoisePost.jsx" /* webpackChunkName: "component---src-templates-wp-noise-post-wp-noise-post-jsx" */),
  "component---src-templates-wp-water-exercises-post-wp-water-exercises-post-jsx": () => import("./../../../src/templates/WpWaterExercisesPost/WpWaterExercisesPost.jsx" /* webpackChunkName: "component---src-templates-wp-water-exercises-post-wp-water-exercises-post-jsx" */),
  "component---src-templates-wp-water-post-wp-water-post-jsx": () => import("./../../../src/templates/WpWaterPost/WpWaterPost.jsx" /* webpackChunkName: "component---src-templates-wp-water-post-wp-water-post-jsx" */)
}

