import React from 'react';

const initialState = {
  themeName: 'biodiversity',
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_THEME': {
      return {
        ...state,
        themeName: newTheme(action.payload.themeName),
      };
    }
    default:
      throw new Error('bad action');
  }
}

function newTheme(themeName) {
  return themeName;
}

export const ThemeStateContext = React.createContext();
export const ThemeDispatchContext = React.createContext();

const ThemeContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ThemeStateContext.Provider value={state}>
      <ThemeDispatchContext.Provider value={dispatch}>
        {children}
      </ThemeDispatchContext.Provider>
    </ThemeStateContext.Provider>
  );
};

export default ThemeContextProvider;
