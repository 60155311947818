import React from 'react';

const initialState = {
  lang: 'en',
  langName: 'English',
  translationLinks: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LANG': {
      return {
        ...state,
        lang: newLang(action.payload.lang),
      };
    }
    case 'SET_LANG_NAME': {
      return {
        ...state,
        lang: newLangName(action.payload.langName),
      };
    }
    case 'SET_TRANSLATION_LINKS': {
      return {
        ...state,
        translationLinks: newLinks(action.payload.translationLinks),
      };
    }
    default:
      throw new Error('bad action');
  }
}

function newLang(name) {
  return name;
}
function newLangName(name) {
  return name;
}
function newLinks(translationLinks) {
  return translationLinks;
}

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
